import '../styles/CirtificateSec.css';
import i1 from '../assets/cirtificate1.jpeg';
import i2 from '../assets/cirtificate2.jpeg';

function CirtificateSec(){
    return(
        <div className='cirtificate'>
            {/* <span>Our Cirtificates</span> */}
            <div className="cirtificatesec">
                <img src={i1} alt="letter1" />
                <img src={i2} alt="letter1" />
            </div>
        </div>
    )
}
export default CirtificateSec;